import React from 'react'
import Link from 'gatsby-link'

const Menu = () => (
    <div style={{
        background: 'darkgoldenrod',
        paddingTop: '2px',
        textDecoration: 'none'
    }}>

    <ul style={{
        listStyle: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: 920,
        margin: `10px auto`,
        alignContent: 'left',
        }}>
        <Link to="/"><li class='btn-oval'>Home</li></Link>
        <Link to="/room"><li class='btn-oval'>Rooms</li></Link>
        <Link to="/activity"><li class='btn-oval'>Nearby</li></Link>
        <Link to="/contact"><li class='btn-oval'>Contact Us</li></Link>
    </ul>
    </div>
)

export default Menu